<template>
    <div>
        <img src="../../assets/images/back.png" alt="" width="30" height="30"
             class="back-logo" @click="goBack">
        <div class="verify-box" id="verify-box">
            <div class="title">
                <img src="../../assets/images/nav.png" alt="" width="30" height="30">
                输入验证码
            </div>
            <div class="subtitle">验证码已发送至 {{ phoneNumber }}</div>
            <div class="input-block">
                <input type="number" placeholder="请输入验证码" v-model="verifyCode" ref="input" @input="goVerify"
                oninput="if(value.length>6)value=value.slice(0,6)" @keyup.enter="verifyIdentity" maxlength="11">
            </div>
            <div class="resend" v-if="!showButton">{{ time }}s后可重新获取</div>
            <div class="button" @click="sendVerifyCode" v-else>发送验证码</div>
            <div class="login-button" @click="verifyIdentity">
                <van-loading color="white" size="24px" v-show="isLoading"/>
                <span v-show="!isLoading">{{ requireInvitationCode ? '下一步' : '登录' }}</span>
            </div>
        </div>
    </div>
</template>

<script>
const seo = require('../../../config/index')
import {  getBaseInfos } from '@/utils/index';
import { sms, createUser, queryUserByPhoneNum, followAuthor, addPartnerNoPaid, login } from '@/api/api'
// @ is an alias to /src

export default {
  name: 'Verify',
  created(){
      this.timer()
  },
  mounted(){
    this.$refs.input.focus()
    if(this.$store.state.userAgent === 'PC'){
        document.getElementById("verify-box").style.cssText= 'margin-top: 128px; border: #ddd 1px solid';
    }
  },
  components: {
  },
  data:()=>{
    return {
        phoneNumber: localStorage.getItem('phoneNumber'),
        verifyCode: '',
        time: 30,
        showButton: false,
        isLoading: false,
        requireInvitationCode: seo.config.requireInvitationCode, // 内测状态需要使用邀请码
    }
  },
  destroyed(){
    if(!this.$route.query.tid){
        sessionStorage.removeItem('fromPath')
    }
    sessionStorage.removeItem('verifyCode')
  },
  methods:{
      // 编码函数
      urlencode (str) {
          str = (str + '').toString();
          return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').
              replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
      },
      isWeiXin() {
          var ua = window.navigator.userAgent.toLowerCase();
          let isWechat = ua.match(/MicroMessenger/i) == 'micromessenger'
          let isMiniProgram = /miniProgram/i.test(ua);
          if (isWechat || isMiniProgram) {
              return true;
          } else {
              return false;
          }
      },
      verifyIdentity(){
          // 请求接口，核对验证码，成功保存token
            if(this.verifyCode === sessionStorage.getItem('verifyCode')){
                this.$refs.input.blur()
                this.$store.commit('Set_IsLogin_State', true)
                this.isLoading = true
                queryUserByPhoneNum({ phoneNum: this.phoneNumber }).then((res)=>{
                    if(this.requireInvitationCode && (res.data.data.length > 0 && !res.data.data[0].invited || res.data.data.length === 0)){
                        this.$router.push('/invitation')
                    } else {
                        // 数据库中用户不存在则注册为新用户
                        if(res.data.data.length === 0){
                            createUser({ phoneNum: this.phoneNumber }).then((res)=>{
                                console.log('成功注册新用户')
                                localStorage.setItem('userInfo', JSON.stringify(res.data.data))
                                // 新用户默认关注官方账号
                                followAuthor({ uid: res.data.data[0].uid, followUid: 33 }).then(()=>{
                                    console.log('关注成功')
                                })
                            })
                        } else {
                            localStorage.setItem('userInfo', JSON.stringify(res.data.data))
                        }
                        // 建立合伙人未支付状态关系
                        if(sessionStorage.getItem('promoCode') && sessionStorage.getItem('promoCode').length > 0){
                            addPartnerNoPaid({ promo_code: sessionStorage.getItem('promoCode'), uid: res.data.data[0].uid })
                        }
                        // 获取code
                        if(this.isWeiXin() && seo.config.mode === 'shop'){
                            console.log("来自微信内置浏览器")
                            setTimeout(()=>{
                                this.isLoading = false
                            },1000)
                            let fromPath;
                            if(sessionStorage.getItem('fromPath')){
                                let temp = sessionStorage.getItem('fromPath')
                                if(temp.includes('/')){
                                    fromPath = temp
                                } else {
                                    fromPath = '' + temp
                                }
                            } else {
                                fromPath = ''
                            }
                            login({ uid: res.data.data[0].uid }).then(result=>{
                                localStorage.setItem('token', result.data.token)
                            })
                            getBaseInfos(fromPath)
                        } else {
                            let uid
                            if(res.data.data.length === 0){
                                uid = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0].uid : ''
                            } else {
                                uid = res.data.data[0].uid
                            }
                            login({ uid: uid }).then(result=>{
                                localStorage.setItem('token', result.data.token)
                                setTimeout(()=>{
                                    this.isLoading = false
                                    if(sessionStorage.getItem('fromPath')){
                                        if(sessionStorage.getItem('fromPath').includes('shop-order-icon')){
                                            this.$router.push('/order')
                                        } else if(sessionStorage.getItem('fromPath').includes('shop-dialog')){
                                            this.$router.push('/shop')
                                        } else if(sessionStorage.getItem('fromPath').includes('personal')){
                                            this.$router.push('/personal')
                                        } else if(sessionStorage.getItem('fromPath').includes('chat')){
                                            this.$router.push('/chat')
                                        } else if(sessionStorage.getItem('fromPath').includes('partner')){
                                            this.$router.push('/partner')
                                        } else if(sessionStorage.getItem('fromPath').includes('activesvip')){
                                            this.$router.push('/activesvip')
                                        } else if(sessionStorage.getItem('fromPath').includes('claimcoupon')){
                                            this.$router.push('/claim-coupon')
                                            // let coupon = JSON.parse(sessionStorage.getItem('coupon'))
                                            // this.$router.push({ path:'/claim-coupon', query:{ type: coupon.type, range: coupon.range } })
                                        } else if(sessionStorage.getItem('fromPath').includes('coupon')){
                                            this.$router.push('/coupon')
                                        } else if(sessionStorage.getItem('fromPath').includes('article')){
                                            sessionStorage.setItem('fromPath','verify')
                                            if(this.$route.query.tid){
                                                this.$router.push({ path:'/article', query:{ tid: this.$route.query.tid } })
                                            } else {
                                                this.$router.push('/article')
                                            }
                                        } else {
                                            this.$router.push('/')
                                        }
                                    } else {
                                        this.$router.push('/')
                                    }
                                },1000)
                            })
                        }
                    }
                })
            } else {
                this.verifyCode = ''
                this.$refs.input.focus()
                this.$notify({
                    message: '验证码错误，请重新输入',
                    duration: 2000,
                    color: '#555',
                    background: '#ebedee'
                })
            }
      },
      timer(){
            if(this.time > 0) {
                this.time --
                setTimeout(this.timer, 1000);
            } else {
                this.time = 0
                this.showButton = true
            }
      },
      // 输入满六位数自动登录
      goVerify(){
        if(this.verifyCode.length === 6){
            this.verifyIdentity()
        }
      },
      sendVerifyCode(){
            this.showButton = false
            this.time = 30
            this.timer()
            sms({"phoneNumber": this.phoneNumber}).then((res)=>{
                sessionStorage.setItem('verifyCode', res.data.identifyCode.slice(5,11))
            })
      },
      goBack(){
        this.$router.back()
      },
  }
}
</script>

<style scoped lang="scss">
.back-logo {
    left: 10px;
    top: 10px;
    position: absolute;
}
.verify-box {
    padding: 90px 30px;
    text-align: left;
    box-sizing:border-box;
    background: #fff;
    height: 100vh;
    .title {
        color: #000;
        font-size: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-family: Microsoft soft, Arial, Helvetica, sans-serif;
        img {
            margin-right: 10px;
        }
    }
    .subtitle {
        font-size: 15px;
        color: #aaa;
        text-align: center;
        margin: 10px 0 0 0;
    }
    .resend {
        font-size: 15px;
        color: #aaa;
        width: 80%;
        margin: 10px auto 0;
    }
    .button {
        font-size: 15px;
        color: #191970;
        margin: 10px 0 0 30px;
    }
    .login-button {
        margin: 30px auto;
        border: 1px solid rgba(255, 255, 255, 0.30);
        background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
        color: #FFF;
        width: 80%;
        height: 45px;
        line-height: 45px;
        border-radius: 30px;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .input-block {
        margin: 50px 0 0;
        display: flex;
        justify-content: center;
        width: 100%;
        flex-wrap: nowrap;
        input {
            width: 80%;
            min-height: 30px;
            font-size: 24px;
            color: rgba(0,0,0,0.9);
            background: none;
            border: none;
            outline:none;
            padding: 10px;
            border-bottom: 1px solid #eee;
        }
        input :focus {
            outline: none;
        }
    }
}
</style>
